/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Ring } from "./Ring";

import backButton from "./left_arrow.png";
import closeButton from "./close_button.png";

import "./NetPromoterScoreSurvey.scss";
import loading from "./loading-inverse.gif";
import { NpsScore } from "./NpsScore";

export function NetPromoterScoreSurvey(props) {
  const { customer, requestId } = useParams();

  const { modal, surveyData, customerData, show, initialRating } = props;

  const onHideProps = props.onHide;

  const starsRef = useRef();
  const timerDebounce = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [sent, setSent] = useState(false);
  const [notificationInfo, setNotificationInfo] = useState(null);
  const [rating, setRating] = useState(-1);
  const [comment, setComment] = useState("");

  function debounce(method, delay) {
    if (timerDebounce.current) clearTimeout(timerDebounce.current);
    timerDebounce.current = setTimeout(function () {
      method();
    }, delay);
  }

  const getData = async () => {
    setIsLoading(true);

    var customerData = await axios.get(
      `${process.env.REACT_APP_API_URL}/${customer}/info`
    );

    setCustomerInfo(customerData.data);

    try {
      var notification = await axios.get(
        `${process.env.REACT_APP_API_URL}/${customer}/nps/${requestId}`
      );

      setNotificationInfo(notification.data);

      setIsLoading(false);
    } catch (err) {
      if (err.response.status == 404) {
        setNotFound(true);
        setIsLoading(false);
      }
    }
  };

  const sendResponse = async (partial = false) => {
    if (rating === -1) return;
    if (!partial) setIsSubmitting(true);
    await axios.post(
      `${process.env.REACT_APP_API_URL}/${customer}/nps/${notificationInfo._id}`,
      {
        rating,
        comment,
        partial,
      }
    );
    if (!partial) setIsSubmitting(false);

    if (!partial) setSent(true);
  };

  useEffect(() => {
    if (!modal) getData();
    else {
      setNotificationInfo(surveyData);
      setCustomerInfo(customerData);

      if (initialRating && show) {
        starsRef &&
          starsRef.current &&
          starsRef.current.setRating(initialRating);
      } else {
        starsRef?.current?.setRating(-1);
      }
    }
  }, [customer, requestId, surveyData, customerData, show]);

  useEffect(() => {
    if (!modal) document.body.classList.toggle("nps", true);
  }, []);

  useEffect(() => {
    if (rating > -1) {
      // only valid ratings allowed
      debounce(() => {
        sendResponse(true);
      }, 500);
    }
  }, [rating, comment]);

  const onHide = () => {
    if (
      sent ||
      window.confirm(
        "Deseja cancelar esta avaliação? Os dados serão descartados"
      )
    )
      onHideProps();
  };

  return (
    <>
      {modal && (
        <div
          className={`nps-overlay ${show ? "show" : ""}`}
          onClick={() => {
            onHide();
          }}
        ></div>
      )}

      <div className={`nps-page ${modal ? "modal" : ""} ${show ? "show" : ""}`}>
        {!modal && (
          <div className="logo">
            <img src={customerInfo.logo} alt={customerInfo.name} />
          </div>
        )}

        {modal && (
          <>
            {!sent && (
              <button
                className="back-button"
                onClick={() => {
                  onHide();
                }}
              >
                <img alt="Voltar" src={backButton} />
              </button>
            )}
            {sent && (
              <button
                className="close-button"
                onClick={() => {
                  onHide();
                }}
              >
                <img alt="Fechar" src={closeButton} />
              </button>
            )}
          </>
        )}

        {isLoading && (
          <div className="loading-container">
            <Ring />
          </div>
        )}

        {notificationInfo && (
          <>
            {!sent ? (
              <div className="nps-form">
                <p>{notificationInfo.survey.npsQuestion}</p>

                <NpsScore
                  ref={starsRef}
                  enabled={true}
                  onChange={(rating) => {
                    setRating(rating);
                  }}
                />

                <p>{notificationInfo.survey.commentsQuestion}</p>

                <textarea
                  name="comment"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                ></textarea>

                <button
                  className="submit-button"
                  disabled={rating === -1 || rating < 0 || rating > 10}
                  onClick={() => {
                    setIsSubmitting(true);
                    debounce(() => {
                      sendResponse(false);
                    }, 500);
                  }}
                >
                  enviar resposta{" "}
                  {isSubmitting && <img alt="Carregando..." src={loading} />}
                </button>
              </div>
            ) : (
              <div className="success-message">
                <h2>Obrigado</h2>
                <p>Sua resposta foi enviada com sucesso!</p>
                <p>Agradecemos sua participação em nossa pesquisa</p>
              </div>
            )}
          </>
        )}

        {notFound && !isLoading && (
          <div className="error-message">
            <h2>Pesquisa encerrada ou já respondida</h2>
            <p>Essa pesquisa pode já ter sido encerrada ou você já respondeu</p>
          </div>
        )}
      </div>
    </>
  );
}
